/**
 * a11y-dark theme for JavaScript, CSS, and HTML
 * Based on the okaidia theme: https://github.com/PrismJS/prism/blob/gh-pages/themes/prism-okaidia.css
 * @author ericwbailey
 */

 /* purgecss start ignore */

 code[class*="language-"],
 pre[class*="language-"] {
   color: #f8f8f2;
   background: none;
   font-family: 'Source Code Pro', monospace;
   text-align: left;
   white-space: pre;
   word-spacing: normal;
   word-break: normal;
   word-wrap: normal;
   line-height: 1.5;

   -moz-tab-size: 4;
   -o-tab-size: 4;
   tab-size: 4;

   -webkit-hyphens: none;
   -moz-hyphens: none;
   -ms-hyphens: none;
   hyphens: none;
 }

 /* Code blocks */
 pre[class*="language-"] {
   border-radius: 0.375rem;
   padding: 1.5rem 1.25rem;
   overflow: auto;
 }

 @media(min-width: 768px) {
    pre[class*="language-"] {
      padding: 1.5rem;
    }
 }

 :not(pre) > code[class*="language-"],
 pre[class*="language-"] {
   background: #151515;
 }

 /* Inline code */
 :not(pre) > code[class*="language-"] {
   padding: 0.1em;
   white-space: normal;
   border-radius: 0.375rem;
 }

 .token {
  font-family: 'Source Code Pro', monospace;
  letter-spacing: -0.1px;
  font-size: 0.95rem;
 }

 .plain:only-child {
   display: block;
    margin-bottom: 1rem;
 }

 .token.comment,
 .token.prolog,
 .token.doctype,
 .token.cdata {
   color: #6b6b6b;
 }

 .token.punctuation {
   color: #fefefe;
 }

 .token.property,
 .token.tag,
 .token.constant,
 .token.symbol,
 .token.deleted {
   color: #86BBD8;
 }

 .token.boolean,
 .token.number {
   color: #F03D75;
 }

 .token.selector,
 .token.attr-name,
 .token.string,
 .token.char,
 .token.builtin,
 .token.inserted {
   color: #EDAE49;
 }

 .token.operator,
 .token.entity,
 .token.url,
 .language-css .token.string,
 .style .token.string,
 .token.variable {
   color: #F03D75;
 }

 .token.atrule,
 .token.attr-value,
 .token.function {
   color: #ffd700;
 }

 .token.keyword {
   color: #F03D75;
 }

 .token.regex,
 .token.important {
   color: #ffd700;
 }

 .token.important,
 .token.bold {
   font-weight: bold;
 }

 .token.italic {
   font-style: italic;
 }

 .token.entity {
   cursor: help;
 }

 @media screen and (-ms-high-contrast: active) {
   code[class*="language-"],
   pre[class*="language-"] {
     color: windowText;
     background: window;
   }

   :not(pre) > code[class*="language-"],
   pre[class*="language-"] {
     background: window;
   }

   .token.important {
     background: highlight;
     color: window;
     font-weight: normal;
   }

   .token.atrule,
   .token.attr-value,
   .token.function,
   .token.keyword,
   .token.operator,
   .token.selector {
     font-weight: bold;
   }

   .token.attr-value,
   .token.comment,
   .token.doctype,
   .token.function,
   .token.keyword,
   .token.operator,
   .token.property,
   .token.string {
     color: highlight;
   }

   .token.attr-value,
   .token.url {
     font-weight: normal;
   }
 }

 /* purgecss end ignore */